<script setup>
import { useDark} from "@vueuse/core";
import { Switch } from "@headlessui/vue";

const enabled = ref();
const isDark = useDark({
  initialValue: 'dark'
})


onMounted(() => {
  enabled.value = isDark.value;
});

debouncedWatch(
  enabled,
  () => {
    isDark.value = enabled.value;
  },
  { debounce: 50 }
);

useHead({
    script: [{
        children: `if (localStorage.getItem('vueuse-color-scheme') === 'dark') {
                    document.documentElement.classList.add('dark');
                  } else if (localStorage.getItem('vueuse-color-scheme') === 'auto') {
                    document.documentElement.classList.remove('dark');
                  }`}]
})
</script>

<template>
  
  <Switch v-model="enabled" :class="[
    enabled ? 'bg-ngsi' : 'bg-gray-200 dark:bg-gray-700',
    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
  ]">
    <span class="sr-only">Use setting</span>
    <span :class="[
      enabled ? 'translate-x-5' : 'translate-x-0',
      'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
    ]">
      <span :class="[
        enabled
          ? 'opacity-0 duration-100 ease-out'
          : 'opacity-100 duration-200 ease-in',
        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
      ]" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 text-yellow-500" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M17 12c0 2.762-2.238 5-5 5s-5-2.238-5-5 2.238-5 5-5 5 2.238 5 5zm-9.184-5.599l-3.594-3.594-1.414 1.414 3.594 3.595c.402-.537.878-1.013 1.414-1.415zm4.184-1.401c.34 0 .672.033 1 .08v-5.08h-2v5.08c.328-.047.66-.08 1-.08zm5.598 2.815l3.594-3.595-1.414-1.414-3.594 3.595c.536.402 1.012.878 1.414 1.414zm-12.598 4.185c0-.34.033-.672.08-1h-5.08v2h5.08c-.047-.328-.08-.66-.08-1zm11.185 5.598l3.594 3.593 1.415-1.414-3.594-3.594c-.403.537-.879 1.013-1.415 1.415zm-9.784-1.414l-3.593 3.593 1.414 1.414 3.593-3.593c-.536-.402-1.011-.877-1.414-1.414zm12.519-5.184c.047.328.08.66.08 1s-.033.672-.08 1h5.08v-2h-5.08zm-6.92 8c-.34 0-.672-.033-1-.08v5.08h2v-5.08c-.328.047-.66.08-1 .08z" />
        </svg>
      </span>
      <span :class="[
        enabled
          ? 'opacity-100 duration-200 ease-in'
          : 'opacity-0 duration-100 ease-out',
        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
      ]" aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 text-ngsi" viewBox="0 0 24 24" fill="currentColor">
          <path
            d="M12 10.999c1.437.438 2.562 1.564 2.999 3.001.44-1.437 1.565-2.562 3.001-3-1.436-.439-2.561-1.563-3.001-3-.437 1.436-1.562 2.561-2.999 2.999zm8.001.001c.958.293 1.707 1.042 2 2.001.291-.959 1.042-1.709 1.999-2.001-.957-.292-1.707-1.042-2-2-.293.958-1.042 1.708-1.999 2zm-1-9c-.437 1.437-1.563 2.562-2.998 3.001 1.438.44 2.561 1.564 3.001 3.002.437-1.438 1.563-2.563 2.996-3.002-1.433-.437-2.559-1.564-2.999-3.001zm-7.001 22c-6.617 0-12-5.383-12-12s5.383-12 12-12c1.894 0 3.63.497 5.37 1.179-2.948.504-9.37 3.266-9.37 10.821 0 7.454 5.917 10.208 9.37 10.821-1.5.846-3.476 1.179-5.37 1.179z" />
        </svg>
      </span>
    </span>
  </Switch>
</template>

<script setup>
    const cookieStatus = ref("declined");
    const handleCookie = () => {
        cookieStatus.value = "accepted";
        localStorage.setItem('Cookies', cookieStatus.value);
    }

    onBeforeMount(() => {
        const test = localStorage.getItem('Cookies');
        if(test){

            cookieStatus.value = test;
        }
    });


</script>

<template>
    <section v-if="cookieStatus=='declined'" class="fixed z-20 bottom-0 w-full bg-gray-50 dark:bg-gray-800">
        <div class="max-w-7xl  mx-auto mt-4 md:mt-6 mb-4 px-4 md:px-8 xl:px-0 lg:flex md:gap-x-16">
            <div>
                <h6 class="text-xl text-gray-500 dark:text-gray-100 mb-1">{{ $t("cookie-title") }}</h6>
                <p class="text-sm text-gray-600 dark:text-gray-300">{{ $t("cookie-description") }}</p>
            </div>

            <div class="flex items-center mt-6 gap-x-4 lg:gap-x-8 shrink-0 lg:mt-0 justify-center">
                <button 
                    class="w-1/2 text-sm text-gray-800 underline transition-colors duration-300 md:w-auto dark:text-white dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none">
                    <NuxtLink :to="localePath('/dataprotection')">
                        {{ $t("cookie-info-button") }}
                    </NuxtLink>
                </button>

                <button @click="handleCookie"
                    class="text-sm w-1/2 md:w-auto font-medium bg-gray-900 rounded-lg hover:bg-gray-900/80 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none">
                    {{ $t("cookie-accept-button") }}
                </button>
            </div>
        </div>
</section>
</template>
<script setup>
  import solutions from '@/data/newsolution.json';

  const local = useLocal();

</script>

<template>
  <footer class="pb-6">
    <div
      class="max-w-7xl 3xl:max-w-8xl mx-auto px-4 md:px-8 xl:px-0 divide-y dark:divide-gray-800 md:space-y-14 dark:border-gray-800 border-t">
      <div class="grid grid-cols-12 md:gap-0 gap-y-0 md:gap-y-0 mt-10">
        <div class="col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-4 xl:mx-10">
          <!-- <img src="/logo-ngsi.png" class="w-40 mb-4 mx-auto" alt="" /> -->
          <div class="text-sm text-gray-700 dark:text-gray-400 m-3 mt-8">
            <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%"
              src="https://maps.google.com/maps?q=NGSI%20GmbH%20Im%20B%C3%BChl%2012%2C%2071287%20Weissach%20-%20Leonberg&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
              aria-label="NGSI GmbH Im Bühl 12, 71287 Weissach - Leonberg"></iframe>
          </div>
        </div>
        <div class="col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-8 grid md:grid-cols-3 lg:grid-cols-3 gap-0 m-3">

          <div>
            <div class="font-semibold uppercase text-sm mb-3.5 dark:text-gray-400 mt-3">
              {{ $t("about-us") }}
            </div>
            <p class="text-xs pr-10 md:pr-20 text-gray-700 dark:text-gray-500"> {{ $t("footer-about-us") }}</p>
          </div>

          <div>
            <div class="font-semibold uppercase text-sm mb-3.5 dark:text-gray-400 mt-3">
              {{ $t("services") }}
            </div>
            <ul class="text-sm space-y-2.5 text-gray-700 dark:text-gray-500">
              <li v-for="solution in solutions[local]" :key="solution.title">
                <NuxtLink class="dark:hover:text-blue-500" :to="localePath(solution.target)" >
                  {{ solution.contentTitle }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <div>
            <div class="font-semibold uppercase text-sm mb-3.5 dark:text-gray-400 mt-3">
              {{ $t("contact") }}
            </div>
            <ul class="text-sm space-y-2.5 text-gray-700 dark:text-gray-500">
              
              <li class="flex items-center"><img class="w-4 h-4 mr-1" src="/ger-lang-icon.png" alt="">Im Bühl 12, 71287 Weissach-Flacht</li>
              <li class="flex items-center"><img class="w-4 h-4 mr-1" src="/tur-lang-icon.png" alt="">Kücükbakkalköy Mah. Defne Sk. No:1 Flora Residence D:2805 Atasehir - İstanbul</li>
              <li class=" flex items-center"><svg class="svg-icon w-4 h-4 mr-1" viewBox="0 0 20 20">
							<path fill="grey" d="M19.291,3.026c0.002-0.15-0.053-0.301-0.167-0.415c-0.122-0.122-0.286-0.172-0.444-0.161H1.196
								c-0.16-0.011-0.322,0.039-0.444,0.161C0.637,2.725,0.583,2.875,0.585,3.026c0,0.003-0.002,0.006-0.002,0.009v14.032
								c0,0.322,0.262,0.584,0.585,0.584h17.54c0.322,0,0.584-0.262,0.584-0.584V3.035C19.292,3.032,19.291,3.029,19.291,3.026z
								 M17.147,3.619l-7.21,6.251L2.728,3.619H17.147z M18.122,15.896c0,0.323-0.261,0.584-0.584,0.584H2.337
								c-0.323,0-0.585-0.261-0.585-0.584V4.292l7.732,6.704c0.013,0.017,0.019,0.035,0.034,0.052c0.115,0.114,0.268,0.169,0.419,0.166
								c0.151,0.003,0.304-0.052,0.419-0.166c0.015-0.017,0.021-0.035,0.034-0.052l7.731-6.704V15.896z"></path>
						</svg><a href="mailto:info@ngsi.de" class="dark:text-blue-500">info@ngsi.de</a></li>
              <li class=" flex items-center"><svg class="svg-icon w-4 h-4 mr-1" viewBox="0 0 20 20">
							<path fill="grey" class="dark:text-white" d="M10,15.654c-0.417,0-0.754,0.338-0.754,0.754S9.583,17.162,10,17.162s0.754-0.338,0.754-0.754S10.417,15.654,10,15.654z M14.523,1.33H5.477c-0.833,0-1.508,0.675-1.508,1.508v14.324c0,0.833,0.675,1.508,1.508,1.508h9.047c0.833,0,1.508-0.675,1.508-1.508V2.838C16.031,2.005,15.356,1.33,14.523,1.33z M15.277,17.162c0,0.416-0.338,0.754-0.754,0.754H5.477c-0.417,0-0.754-0.338-0.754-0.754V2.838c0-0.417,0.337-0.754,0.754-0.754h9.047c0.416,0,0.754,0.337,0.754,0.754V17.162zM13.77,2.838H6.23c-0.417,0-0.754,0.337-0.754,0.754v10.555c0,0.416,0.337,0.754,0.754,0.754h7.539c0.416,0,0.754-0.338,0.754-0.754V3.592C14.523,3.175,14.186,2.838,13.77,2.838z M13.77,13.77c0,0.208-0.169,0.377-0.377,0.377H6.607c-0.208,0-0.377-0.169-0.377-0.377V3.969c0-0.208,0.169-0.377,0.377-0.377h6.785c0.208,0,0.377,0.169,0.377,0.377V13.77z"></path>
						</svg><a class="dark:text-blue-500" href="tel:+49 (0) 7152 - 450 80 03">+49 (0) 7152 - 450 80 03</a></li>
              <li class=" flex items-center"><svg class="svg-icon w-4 h-4 mr-1" viewBox="0 0 20 20" fill="grey">
							<path d="M18.125,15.804l-4.038-4.037c0.675-1.079,1.012-2.308,1.01-3.534C15.089,4.62,12.199,1.75,8.584,1.75C4.815,1.75,1.982,4.726,2,8.286c0.021,3.577,2.908,6.549,6.578,6.549c1.241,0,2.417-0.347,3.44-0.985l4.032,4.026c0.167,0.166,0.43,0.166,0.596,0l1.479-1.478C18.292,16.234,18.292,15.968,18.125,15.804 M8.578,13.99c-3.198,0-5.716-2.593-5.733-5.71c-0.017-3.084,2.438-5.686,5.74-5.686c3.197,0,5.625,2.493,5.64,5.624C14.242,11.548,11.621,13.99,8.578,13.99 M16.349,16.981l-3.637-3.635c0.131-0.11,0.721-0.695,0.876-0.884l3.642,3.639L16.349,16.981z"></path>
						</svg> www.ngsi.de</li>

            </ul>
            <span class="text-sm space-y-2.5 text-gray-700 dark:text-gray-500">

              <br>
              <br>
              <br>

            </span>
          </div>
        </div>
      </div>

      <div class="pt-6 text-[0.825rem] text-gray-500">
        <div class="flex flex-col md:flex-row space-y-4 md:space-y-0 items-center justify-center">
          <ul class="flex items-center space-x-4 md:justify-start md:space-x-6">
            <li>© 2023, NGSI GmbH</li>
            <li>
              <nuxt-link to="/" class="hover:text-gray-700">
                License
              </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/" class="hover:text-gray-700"> Terms </nuxt-link>
            </li>
            <li>
              <nuxt-link to="/" class="hover:text-gray-700">
                Privacy
              </nuxt-link>
            </li>
          </ul>
          <!-- <div class="flex items-center space-x-6">
            <span class="inline-block text-gray-400 text-sm">Join us on</span>
            <ul class="flex items-center space-x-4">
              <li>
                <nuxt-link to="/" class="text-gray-400 hover:text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                  </svg>
                </nuxt-link>
              </li>
              <li>
                <nuxt-link to="/" class="text-gray-400 hover:text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </nuxt-link>
              </li>
              <li>
                <nuxt-link target="blank" to="https://www.linkedin.com/company/ngsi-next-gen-solution-innovation/about/" class="text-gray-400 hover:text-gray-600 animate-pulse">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                    <path
                      d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                  </svg>
                </nuxt-link>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import solutions from '@/data/newsolution.json';
import { useDark} from "@vueuse/core";

const isDark = useDark()

const emits = defineEmits(["open-mobile-menu"]);


const local = useLocal();

const isSticky = ref(false);

const scrollHandler = () => {
  if (isSticky.value && window?.scrollY < 250) {
    isSticky.value = false;
  } else if (window?.scrollY > 340) {
    isSticky.value = true;
  }
};

const showMenuServices = ref(false)

const toogleShow = () =>{
  showMenuServices.value = !showMenuServices.value
}

const handleClickOutside = (event) => {
  const menu = document.querySelector(".nav-sub-menu");
  const menuOpener = document.querySelector(".nav-opener");
  try {
    if (!menuOpener.contains(event?.target) && !menu.contains(event?.target)) {
    showMenuServices.value = false;
  }
  } catch (error) {
    // console.log(error);
  }

};


onMounted(async () => {
  await nextTick();
  document.addEventListener("scroll", scrollHandler);
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("scroll", scrollHandler);
  document.removeEventListener("click", handleClickOutside);
});




</script>

<template>
  <header
    class=" border-b dark:border-b-gray-800 pb-4 space-y-1 px-4 md:px-8 xl:px-0 sticky top-0 bg-white dark:bg-gray-900 bg-opacity-95 backdrop-blur z-20 pt-4" >
    <div class="grid grid-cols-12 max-w-7xl 3xl:max-w-8xl mx-auto">
      <div class="col-span-9 lg:col-span-3">
        <nuxt-link :to="localePath('/')" class="flex items-center space-x-2.5" title="Home">
          <img v-if="isDark" src="/ngsi-flat-dark.png" class="transition-all duration-200" :class="[isSticky ? 'w-36' : 'w-48']" alt="" />
          <img v-if="!isDark" src="/ngsi-flat.png" class="transition-all duration-200" :class="[isSticky ? 'w-36' : 'w-48']" alt="" />
          <!-- <span class="font-semibold dark:text-white" :class="[isSticky ? 'text-lg' : 'text-xl']">NGSI</span> -->
        </nuxt-link>
      </div>
      <nav role="navigation" class="col-span-6 items-center justify-center dark:text-gray-200 hidden lg:flex">
        <ul class="flex items-center space-x-10 transition-all duration-200 text-base">
          <li>
            <nuxt-link :to="localePath('/aboutus')" class="hover:text-gray-700 dark:hover:text-gray-50">{{ $t("about-us") }}</nuxt-link>
          </li>
          <li>
            <div  class="relative inline-block text-left group">
              <div @click="toogleShow" class="nav-opener cursor-pointer">
                <nuxt-link class="hover:text-gray-700 dark:hover:text-gray-50">{{ $t("services") }}  </nuxt-link> <i class="opacity-30" aria-hidden="true">▼</i>
              </div>
              <div v-if="showMenuServices" class="nav-sub-menu opacity-100 absolute mt-2 z-10 py-2 px-2 rounded-md shadow-lg bg-white dark:bg-gray-900 border-4 border-l-indigo-500 transform scale-95 transition-all duration-300">
                <NuxtLink v-for="item in solutions[local]" :key="item.title" :to="localePath(item.target)" class="block whitespace-nowrap px-4 py-2 hover:text-gray-700 dark:hover:text-gray-50  border-b dark:border-gray-900 hover:border-gray-500 dark:hover:border-gray-500 hover:scale-95 transition-all duration-300">{{ item.contentTitle }}</NuxtLink>
              </div>
            </div>
          </li>
          <li>
            <nuxt-link :to="localePath('/contact')" class="hover:text-gray-700 dark:hover:text-gray-50">{{ $t("contact") }}</nuxt-link>
          </li>

        </ul>
      </nav>
      <ul class="col-span-3 hidden lg:flex items-center justify-end space-x-4 text-sm dark:text-gray-400">
        <li>
          <ModeSwitcher />
        </li>
        <li>
          <LangSwitcher/>
        </li>
      </ul>
      <button type="button" class="dark:text-gray-200 font-semibold block lg:hidden col-span-3 ml-auto"
        @click="$emit('open-mobile-menu')">
        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 dark:text-gray-200" fill="currentColor">
          <path
            d="m11 16.745c0-.414.336-.75.75-.75h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75zm-9-5c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm4-5c0-.414.336-.75.75-.75h14.5c.414 0 .75.336.75.75s-.336.75-.75.75h-14.5c-.414 0-.75-.336-.75-.75z"
            fill-rule="nonzero" />
        </svg>
      </button>
    </div>
  </header>
</template>

<template>
  <TransitionRoot appear :show="props.status" as="template">
    <Dialog as="div" @close="closeFun()" class="relative z-40">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-gray-100 dark:bg-gray-800 p-6 mb-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-100">

              </DialogTitle>
              <div class="mt-2">
                <ul v-for="solution in solutions[local]">
                  <li @click="handleSelect(solution.target)" class="cursor-pointer hover:bg-gray-700 text-lg dark:text-gray-400 border-b py-6 border-gray-300 dark:border-gray-400 ">
                    <nuxt-link class=" text-gray-900 dark:text-gray-200 font-medium text-base px-2" >
                      {{ solution.contentTitle }}</nuxt-link>
                  </li>
                </ul>
              </div>

              <div class="mt-4">
                <button type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  @click="closeFun()">
                  {{$t("modal-close")}}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
  
<script setup>
import solutions from '@/data/newsolution.json';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'

const local = useLocal();

const props = defineProps({
  status: Boolean,
  closeFun: Function,
  local: String,
})


const handleSelect = (route) => {
  const newroute = "/" + local.value + route;
  navigateTo(newroute)
}
</script>
  
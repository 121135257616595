<script setup>
import menuItems from '@/data/mobilHeader.json';

const showMobileMenu = inject("showMobileMenu");

const local = useLocal();

const isOpen = ref(false)

function openModal() {
  isOpen.value = true
}

function closeModal() {
  isOpen.value = false
}


</script>

<template>
  <transition enter-active-class="transition duration-200 transform" enter-from-class="translate-x-1/2 opacity-0"
    enter-to-class="opacity-100 translate-x-0" leave-active-class="transition duration-200 transform"
    leave-from-class="translate-x-0 opacity-100" leave-to-class="opacity-0 translate-x-1/2">
    <div v-if="showMobileMenu" class="fixed top-0 left-0 w-full h-full bg-white dark:bg-gray-800 z-40">
      <Modal :status="isOpen" :closeFun="closeModal" />
      <div class="flex items-center justify-between px-4 md:px-8 py-4">
        <img src="/ngsi-flat.png" class="w-48" alt="" />
        <button type="button" @click="showMobileMenu = false">
          <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg" class="text-gray-500 dark:text-gray-400 w-10 h-10" fill="currentColor">
            <path
              d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
          </svg>
        </button>
      </div>
      <ul class="flex space-x-6 items-center text-base dark:text-gray-400 mt-6 px-6 md:px-8">
        <li>
          <ModeSwitcher />
        </li>
        <li>
          <LangSwitcher />
        </li>
      </ul>

      <nav role="navigation" class="max-w-7xl mx-auto dark:text-gray-200">
        <ul class="flex flex-col items-start mt-4 text-xl divide-y dark:divide-gray-700">
          <li class="w-full py-4 md:py-6 px-6 md:px-8" v-for="item in menuItems[local]" :key="item.title">
            <nuxt-link v-if="item.target != '/solutions'" :to="localePath(item.target)"
              class="hover:text-gray-700 dark:hover:text-gray-50 flex items-center justify-between">
              <div>
                <div class="font-medium text-base">{{ item.title }}</div>
                <p class="text-sm font-normal text-gray-600 dark:text-gray-400">
                  {{ item.text }}
                </p>
              </div>
            </nuxt-link>
            <nuxt-link v-if="item.target == '/solutions'" @click="openModal"
              class="cursor-pointer hover:text-gray-700 dark:hover:text-gray-50 flex items-center justify-between">
              <div>
                <div class="font-medium text-base">{{ item.title }}</div>
                <p class="text-sm font-normal text-gray-600 dark:text-gray-400">
                  {{ item.text }}
                </p>
              </div>

              <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"
                class="w-10 h-10 text-ngsi" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m10.211 7.155c-.141-.108-.3-.157-.456-.157-.389 0-.755.306-.755.749v8.501c0 .445.367.75.755.75.157 0 .316-.05.457-.159 1.554-1.203 4.199-3.252 5.498-4.258.184-.142.29-.36.29-.592 0-.23-.107-.449-.291-.591-1.299-1.002-3.945-3.044-5.498-4.243z" />
              </svg>
            </nuxt-link>
          </li>

        </ul>
      </nav>
    </div>
  </transition>
</template>

<script setup>

const { setLocale,locale } = useI18n();

const local = useLocal();
local.value = locale.value;

function setLanguage(language) {
    setLocale(language);
    local.value = language;
    localStorage.setItem('language', language);
}


</script>

<template>
    <div class="flex space-x-1 ">
        <NuxtLink :to="switchLocalePath('de')" @click="setLanguage('de')"
            class="hover:text-gray-700 dark:hover:text-gray-300">
            <div class="group items-center justify-center flex">
                <img class="w-6 h-6 group-hover:opacity-0 transition transform duration-500" src="/ger-lang-icon.png" />
                <h1
                    class="absolute opacity-0 group-hover:opacity-100 group-hover:flex w-6 h-6 justify-center items-center flex transition transform duration-500 ">
                    DE</h1>
            </div>
        </NuxtLink>
        <NuxtLink :to="switchLocalePath('en')" @click="setLanguage('en')"
            class="hover:text-gray-700 dark:hover:text-gray-300">
            <div class="group items-center justify-center flex ">
                <img class="w-6 h-6 group-hover:opacity-0 transition transform duration-500" src="/eng-lang-icon.png" />
                <h1
                    class="absolute opacity-0 group-hover:opacity-100 group-hover:flex w-6 h-6 justify-center items-center flex transition transform duration-500 ">
                    EN</h1>
            </div>
        </NuxtLink>
        <NuxtLink :to="switchLocalePath('tr')" @click="setLanguage('tr')"
            class="hover:text-gray-700 dark:hover:text-gray-300">
            <div class="group items-center justify-center flex">
            <img class="w-6 h-6 group-hover:opacity-0 transition transform duration-500" src="/tur-lang-icon.png" />
            <h1
                class="absolute opacity-0 group-hover:opacity-100 group-hover:flex w-6 h-6 justify-center items-center flex transition transform duration-500 ">
                TR</h1>
        </div>
    </NuxtLink>
</div></template>